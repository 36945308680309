<div style="width: 100%" class="page-borders">
  <div class="m-5 flex flex-row">
    <div class="page-header">Data Import/Export</div>
    <div class="flex-1"></div>
    <div class="pt-4 flex" class="float-right">
      <button mat-flat-button color="primary" class="float-right mr-1 text-uppercase" (click)="saveMappings()">
        Save
      </button>
    </div>
  </div>
  <mat-tab-group class="inner-mat-tab mat-tab-no-border m-5" (selectedTabChange)="selectCategory($event)">
    <mat-tab label="Order">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #orderPaginator>
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="Export">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #exportPaginator>
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="Applicant">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #applicantPaginator>
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="STP">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #stpPaginator>
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="Acord 103">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <div class="flex-md-row" style="margin-bottom: 16px">
              Map your data to the fields needed to create a case from an ACORD 103.  You can map any source value from your data to its matching Mapkey.
<br><br>
              You can also import and export mappings to quickly update or share multiple mappings at once.
            </div>
            <div id="toolbar" class="flex-md-row" style="margin-bottom: 16px;text-align: end">
              <span class="d-inline">
                  <input (change)="selectFile($event)" (click)="onClick($event)" accept=".csv"
                      class="d-none" id="fileInput" type="file" #fileInput />
                  <mrs-btn-outline iconName='upload' buttonLabel="Import" buttonId="import"                      
                      (onClick)="fileInput.click()"
                      matTooltip="Import Mappings">
                  </mrs-btn-outline>
              </span>
              <span class="d-inline">
                  <mrs-btn-outline iconName='download' buttonLabel="Export" buttonId="export"
                      (click)="exportList()" matTooltip="Export Mappings">
                  </mrs-btn-outline>
              </span>
          </div>
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #acord103Paginator id="acord103Paginator">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>    
    <mat-tab label="CSV">
      <div style="width: 100%;">
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px">
            <ng-container *ngTemplateOutlet="standardDataEntry"></ng-container>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #csvPaginator>
            </mat-paginator>
            <form [formGroup]="csvForm" autocomplete="off">
              <div class="page-sub-header mt-4">Notifications</div>
              <div formArrayName="emailRecipients">
                <div class="primary-background p-3">
                  <div class="section-header mt-4">Send Upload Results To</div>
                  <div class="flex flex-column" style="width: 350px">
                    <ng-container *ngFor="let control of emailRecipients.controls; let i = index; first as isFirst">
                      <div [formGroupName]="i" class="flex flex-row" style="margin-top: 15px">
                        <mat-form-field class="w-100">
                          <mat-label>Email</mat-label>
                          <input type="email" matInput formControlName="emailAddress">
                          <mat-error
                            *ngIf="formSubmitted && csvForm.get('emailRecipients.' + i + '.emailAddress').hasError('required')">
                            Email Address <strong>required</strong>
                          </mat-error>
                          <mat-error
                            *ngIf="formSubmitted && csvForm.get('emailRecipients.' + i + '.emailAddress').hasError('email')">
                            Valid Email Address <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                        <button mat-icon-button (click)="deleteEmailRecipient(i)" class="mt-2" *ngIf="!first">
                          <i aria-hidden="true" title="Delete" class="fa fa-trash"></i>
                        </button>
                      </div>
                    </ng-container>
                    <button type="button" mat-button color="primary" class="mr-1 material-default text-uppercase"
                      (click)="addEmailRecipient()" style="padding-left: 0; width: 150px">
                      <mat-icon style="margin-bottom: 4px;">add</mat-icon>Add Recipient
                    </button>
                  </div>
                </div>
              </div>
              <div class="page-sub-header mt-4">Connection</div>
              <div class="flex flex-row primary-background p-3">
                <div class="flex flex-column mr-5" style="width:50%">
                  <div class="section-header mt-4 mb-3">Protocol/Location</div>
                  <mat-radio-group aria-label="Select a protocol" formControlName="protocol">
                    <mat-radio-button value="ftp">FTP</mat-radio-button>
                    <mat-radio-button value="sftp">SFTP</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="formSubmitted && csvForm.get('protocol').hasError('required')">
                    Protocol <strong>required</strong>
                  </mat-error>
                  <mat-form-field>
                    <mat-label>Server Name</mat-label>
                    <input type="text" matInput formControlName="serverName" width="150px">
                    <mat-error *ngIf="formSubmitted && csvForm.get('serverName').hasError('required')">
                      Server Name <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Port Number</mat-label>
                    <input type="number" matInput formControlName="portNumber" width="150px">
                    <mat-error *ngIf="formSubmitted && csvForm.get('portNumber').hasError('required')">
                      Port Number <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Remote Path</mat-label>
                    <input type="text" matInput formControlName="remotePath" width="150px">
                    <mat-error *ngIf="formSubmitted && csvForm.get('remotePath').hasError('required')">
                      Remote Path <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <div class="section-header mt-4">Delimiter</div>
                  <mat-form-field>
                      <mat-label>Character</mat-label>
                      <input type="text" matInput formControlName="delimiter" width="150px">
                      <mat-error *ngIf="formSubmitted && csvForm.get('delimiter').hasError('required')">
                          Character <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="csvForm.get('delimiter').hasError('pattern')">
                          Cannot start with a space
                      </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex flex-column" style="width:50%">
                  <div class="section-header mt-4 mb-3">Authentication</div>
                  <mat-radio-group aria-label="Select authentication" formControlName="authenticationType">
                    <mat-radio-button value="privateKey">Private Key</mat-radio-button>
                    <mat-radio-button value="password">Password</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="formSubmitted && csvForm.get('authenticationType').hasError('required')">
                    Authentication Type <strong>required</strong>
                  </mat-error>
                  <mat-form-field>
                    <mat-label>User Name</mat-label>
                    <input type="text" matInput formControlName="userName" width="150px">
                    <mat-error *ngIf="formSubmitted && csvForm.get('userName').hasError('required')">
                      User Name <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Password</mat-label>
                    <mat-select formControlName="password">
                      <mat-option *ngFor="let key of secrets" [value]="key.id">{{key.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="csvForm.get('password').hasError('required')">required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Passphrase</mat-label>
                    <mat-select formControlName="passphrase"
                      [disabled]="csvForm.get('authenticationType').value !== 'privateKey'">
                      <mat-option *ngFor="let key of secrets" [value]="key.id">{{key.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="csvForm.get('passphrase').hasError('required')">required</mat-error>
                  </mat-form-field>
                  <div class="section-header mt-4">Encryption</div>
                  <mat-form-field class="w-100">
                    <mat-label>PGP Key</mat-label>
                    <mat-select formControlName="pgpKey">
                      <mat-option *ngFor="let key of secrets" [value]="key.id">{{key.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #standardDataEntry>
  <div class="flex flex-row">
    <div class="page-sub-header">Mapping</div>
    <button type="button" mat-button color="primary" class="ml-3 material-default" (click)="addApiMapping()"
      style="text-transform: uppercase; padding-left: 0;">
      <mat-icon style="margin-bottom: 4px;">add</mat-icon>Add Mapping
    </button>
  </div>
  <form [formGroup]="mappingForm" autocomplete="off">
    <div *ngIf="selectedCategory != null && selectedCategory != ''">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="elementName" matSortDirection="asc"
        style="width:100%;" class="editable-table">
        <ng-container matColumnDef="elementName" class="col-1">
          <th mat-header-cell *matHeaderCellDef id="sourceValue"> {{sourceColumnName}}</th>
          <td mat-cell *matCellDef="let element;" [formGroup]="element">
            <mat-form-field floatLabel="never" style="width: 100%; padding-right: 25px;" *ngIf="selectedCategory !== Acord103">
              <mat-label>Source value</mat-label>
              <input matInput formControlName="elementName" id="elementName" [attr.data-test-value]="element.get('elementName').value">
              <mat-error *ngIf="formSubmitted && element.get('elementName').hasError('required')">
                {{sourceColumnName}}
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="never" style="width: 100%; padding-right: 25px;" *ngIf="selectedCategory === Acord103">
              <mat-label>Source value</mat-label>
              <input matInput formControlName="elementName" id="elementName">
              <mat-error *ngIf="formSubmitted && element.get('source').hasError('required')">
                Source
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="mapKeyId" class="col-1">
          <th mat-header-cell *matHeaderCellDef> Mapkey</th>
          <td mat-cell *matCellDef="let element" [formGroup]="element">
            <map-keys-dropdown [showRequiredError]="formSubmitted && element.get('mapKeyId').hasError('required')"
              [allowedTypes]="allowedMapKeyTypes" [attr.disabled]="true"
              [displayList]="(element.disabled || selectedCategory !== 'STP')?displayedMapkeys:displayedNonQuestionMapkeys"
              placeholder="Mapkey" formControlName="mapKeyId" [formControl]="element.get('mapKeyId')">
            </map-keys-dropdown>
          </td>
        </ng-container>
        <ng-container matColumnDef="validations">
          <th mat-header-cell *matHeaderCellDef style="width:125px">

          </th>
          <td mat-cell *matCellDef="let element">
            <button type="button" class="btn btn-link btn-sm custom" (click)="editValidations(element)"
              *ngIf="!element.disabled">
              {{ element.get("mapKeyValidations").value.length}} Validation(s)
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width:15px">

          </th>
          <td mat-cell class="text-center" *matCellDef="let element">
            <button mat-icon-button (click)="deleteApiMapping(element)" *ngIf="!element.disabled">
              <i aria-hidden="true" title="Delete" class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" style="cursor: default;" [attr.data-test-id]="i"></tr>
      </table>
    </div>
  </form>
</ng-template>



<ng-template #validationDialog let-data style="width: '800px', max-height='90%'">
  <h2 mat-dialog-title>Validation</h2>
  <div mat-dialog-content>
    <form [formGroup]="validationForm" autocomplete="off">
      <table mat-table [dataSource]="validationDataSource" style="width:100%;">
        <ng-container matColumnDef="expression" class="col-1">
          <th mat-header-cell *matHeaderCellDef>
            Validation Expression
            <i aria-hidden="true" class="fas fa-question-circle" (click)="showExpressionHelp()"></i>
          </th>
          <td mat-cell *matCellDef="let element;" [formGroup]="element">
            <mat-form-field floatLabel="never" style="width: 100%; padding-right: 25px">
              <mat-label>Expression</mat-label>
              <input matInput formControlName="expression" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="errorMessage">
          <th mat-header-cell *matHeaderCellDef> Error Message</th>
          <td mat-cell *matCellDef="let element" [formGroup]="element" style="width: 100%; padding-right: 25px">
            <mat-form-field floatLabel="never">
              <mat-label>Error Message</mat-label>
              <input matInput formControlName="errorMessage" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell class="text-center" *matCellDef="let element">
            <button mat-icon-button (click)="deleteValidation(element)">
              <i aria-hidden="true" title="Delete" class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="validationColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: validationColumns;" style="cursor: default;"></tr>
      </table>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button type="button" mat-button class="mr-1 material-default" (click)="addValidation()">
      ADD
    </button>
    <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
      CLOSE
    </button>
  </div>
</ng-template>
<ng-template #expressionHelpDialog let-data>
  <h2 mat-dialog-title>Common Validation Expressions</h2>
  <div mat-dialog-content>
    <table mat-table [dataSource]="expressionHelpDataSource" style="width:100%;">
      <ng-container matColumnDef="name" class="col-1">
        <th mat-header-cell *matHeaderCellDef>
          Name
        </th>
        <td mat-cell *matCellDef="let element;">
          {{element.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="expression">
        <th mat-header-cell *matHeaderCellDef> Expression</th>
        <td mat-cell *matCellDef="let element;" [innerHTML]="element.expression"
          style="padding-left: 10px; padding-right: 10px;">
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          Description
        </th>
        <td mat-cell *matCellDef="let element;" [innerHTML]="element.description">
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="expressionHelpColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: expressionHelpColumns;" style="cursor: default;"></tr>
    </table>
  </div>
  <div mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
      CLOSE
    </button>
  </div>
</ng-template>
