<div class="case-manager-header">
  <div class="main-page-heading">
    Users
  </div>
</div>
<div class="case-manager-container">
  <div class="case-manager-inner-container">
    <manage-users-table [data]="users" [searchableFields]="searchableFields" [displayedColumns]="displayedColumns"
      [showClientFilter]="hasMultipleClients" [clients]="clientSelectListItems" [addRecordAction]="addRecordAction"
      initialSort="nameAndEmail" (tableEvent)="tableAction($event)"></manage-users-table>
  </div>
</div>