import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app.service';
import { MultiSelectModel } from 'app/components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { MrsColor } from 'app/enums';
import { Account } from 'app/models';
import { UserAdminService } from 'app/services';
import { ActionsEntity, TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { groupBy } from 'app/shared/functions';
import { mergeMap, take, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { StatusEnum } from '../enums/global/status.enum';
import { NotificationService, NotificationSeverity } from '../services/notification.service';
import { ConfirmationDialogService } from '../services/utility/confirmation-dialog.service';
import { GetManageUsersResponse } from './models/get-manage-users-response.interface';
import moment = require('moment');

@Component({
    selector: 'app-manage-users-page',
    host: { 'class': 'content' },
    templateUrl: './manage-users-page.component.html',
    styleUrls: ['./manage-users-page.component.scss']
})
export class ManageUsersPageComponent implements OnDestroy, OnInit {

    selectedRecordId: any;
    activeUser: any;
    accounts: Account[];
    canRead = false;
    canWrite = false;
    full = false;
    hasMultipleClients = false;
    clientSelectListItems: MultiSelectModel[];
    users: any;
    displayedColumns: TableColumnDefinition[];
    searchableFields: string[];
    addRecordAction: ActionsEntity;
    private subs = new SubSink();
    disableUserAction: ActionsEntity;
    showDeactivateUserPrompt: boolean;
    
    editUserDialogRef: MatDialogRef<EditUsersComponent>;

    constructor(
        private userAdminService: UserAdminService,
        private appService: AppService,
        private confirmationService: ConfirmationDialogService,
        private notificationService: NotificationService,
        public activatedRoute: ActivatedRoute,
        public dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.canRead = this.appService.checkACL('User Management', 'R');
        this.canWrite = this.appService.checkACL('User Management', 'W');
        this.full = this.appService.checkACL('User Management', 'F');
        this.getUsers();
        this.setupClients();
        this.setupTableInputs();
    }

    setupTableInputs() {
        this.addRecordAction = { actionType: 'add', name: 'Add User', permission: this.canWrite };
        this.disableUserAction = { actionType: 'disable', name: 'Disable User', permission: this.canWrite };

        this.searchableFields = ['name', 'email', 'userRoleName'];
        this.displayedColumns = [
            {
                def: 'nameAndEmail',
                header: 'Name & Email',
                sortFields: ['name', 'email'],
                columnStyles:
                {
                    'width': '40%',
                },
                rowDefs: [
                    {
                        className: 'name-line',
                        dataName: 'name',
                    },
                    {
                        className: 'email-line',
                        dataName: 'email'
                    }
                ]
            },
            {
                def: 'userRoleName',
                header: 'Role',
                sortFields: ['userRoleName'],
                columnStyles:
                {
                    'width': '25%'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'userRoleName'
                    }
                ]
            },
            {
                def: 'createdModified',
                header: 'Created',
                sortFields: ['created'],
                columnStyles:
                {
                    'width': '25%'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'createdModified'
                    }
                ]
            },
            {
                def: 'lastSignInModified',
                header: 'Last Sign In',
                sortFields: ['lastSignIn'],
                columnStyles:
                {
                    'width': '25%'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'lastSignInModified'
                    }
                ]
            },
            {
                def: 'actions',
                header: 'Actions',
                columnStyles:
                {
                    'width': '25%'
                },
                isAction: true,
                actions: [
                    {
                        name: 'Edit User',
                        icon: 'square-edit-outline',
                        actionType: 'edit',
                        permission: this.canWrite
                    },
                    {
                        name: 'Deactivate User',
                        icon: 'account-remove',
                        actionType: 'deactivateUser',
                        permission: this.full,
                        conditions: [
                            {
                                showProperty: 'userStatusId',
                                showValue: StatusEnum.Active
                            },
                            {
                                showProperty: 'connectionType',
                                showValue: 'Database'
                            }
                        ]
                    },
                    {
                        name: 'Reactivate User',
                        icon: 'account-reactivate',
                        actionType: 'reactivateUser',
                        permission: this.full,
                        conditions: [
                            {
                                showProperty: 'userStatusId',
                                showValue: StatusEnum.Inactive
                            },
                            {
                                showProperty: 'connectionType',
                                showValue: 'Database'
                            }
                        ]
                    }
                ]
            }];
    }

    private setUserName(user: GetManageUsersResponse) {
        const isDisabled = user.userStatusId === StatusEnum.Inactive;
        const name = user.name !== undefined ? user.name : '';
        if (!isDisabled) {
            user.userStatusId = StatusEnum.Active;
        }

        return isDisabled ? `${name} (inactive)` : name;
    }

    getUsers() {
        this.subs.add(this.userAdminService.getAllUsers().subscribe(data => {
            this.users = data.map((user: GetManageUsersResponse) => ({
                ...user,
                id: user.id,
                name: this.setUserName(user),
                email: user.email === undefined ? ' ' : user.email,
                userRoleId: user.userRoleId,
                userRoleName: user.userRoleName,
                userStatusId: user.userStatusId,
                userStatusName: user.userStatusName,
                createdModified: moment(user.created).utc().format('l') === '1/1/0001' ? '' : moment(user.created).utc().format('l'),
                lastSignInModified: user.lastSignIn === null ? 'Never' : this.formatLastSignInDate(user.lastSignIn),
                clientIds: user.clientIds,
                disabled: user.userStatusId === StatusEnum.Inactive
            }));
        }));
    }

    setupClients() {
        this.subs.add(this.activatedRoute.data.pipe(
            tap(data => {
                const uniqueClients = groupBy(data.accounts, client => client.clientId);
                const selectModels = new Array<MultiSelectModel>();
                this.hasMultipleClients = uniqueClients && uniqueClients.size > 1;
                if (this.hasMultipleClients) {
                    uniqueClients.forEach(client => {
                        selectModels.push({
                            id: client[0].clientId,
                            name: client[0].clientName,
                            isSelected: false
                        });
                    });
                    this.clientSelectListItems = selectModels;
                }
            })
        ).subscribe(data => {
            this.accounts = data.accounts;
        }));
    }

    tableAction(event) {
        this.setupTableInputs();

        if (event.action?.actionType === 'edit') {
            this.subs.add(this.userAdminService.getUser(event.record?.id).subscribe(result => {
                this.activeUser = result;
                this.addOrEditUser(result);
            }));
        }

        if (event.action?.actionType === 'add') {
            this.activeUser = null;
            this.addOrEditUser(null);
        }

        if (event.action?.actionType === 'deactivateUser' || event.action?.actionType === 'reactivateUser') {
            const isActive = event.record.userStatusId === StatusEnum.Active;
            const title = isActive ? 'Deactivate User?' : 'Reactivate User?';

            if (event.record.name.includes('(inactive)')) {
                event.record.name = event.record.name.replace(' (inactive)', '');
            }
            const successMessage = isActive
                ? `${event.record.name} has been deactivated.`
                : `${event.record.name} has been activated.`;
            const message = isActive
                ? `${event.record.name} will be deactivated. You will be able to reactivate them.`
                : `${event.record.name}'s' account will be restored with their last account settings and permissions.`;

            this.confirmationService.open({
                title: title,
                message: message,
                okLabel: isActive ? 'DEACTIVATE USER' : 'REACTIVATE USER',
                cancelLabel: 'CANCEL',
                okTextColor: isActive ? MrsColor.UIRed : MrsColor.UIBlue,
                onOk: () => {
                    this.subs.add(this.userAdminService.getUser(event.record?.id).pipe(
                        take(1),
                        mergeMap((user) => this.userAdminService.updateUserStatus({
                            userId: user.id,
                            statusId: user.statusId === StatusEnum.Active ? StatusEnum.Inactive : StatusEnum.Active
                        }))
                    ).subscribe(() => {
                        this.notificationService.showNotification({
                            severity: NotificationSeverity.Success,
                            message: `${successMessage}`,
                            actionLabel: 'CLOSE'
                        });
                        this.getUsers();
                    }));
                },
                showCancel: true
            });
        }
    }

    addOrEditUser(user: any): void {
        this.editUserDialogRef = this.dialog.open(EditUsersComponent, {
            width: '50%',
            data: {
                user: user,
                accounts: this.accounts
            }
        });

        this.editUserDialogRef.afterClosed().subscribe( result => {
            if (result) {
                this.getUsers();
            }
        });
    }

    formatLastSignInDate(date: string): string {
        const daysDiff = moment().diff(date, 'days');
        if (daysDiff > 1 && daysDiff < 8) {
            return moment(date).format('dddd');
        }
        if (daysDiff === 1) {
            return 'Yesterday';
        }
        if (daysDiff === 0) {
            return 'Today';
        }

        return moment(date).format('l');
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
