<mat-form-field style="width: 100%">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [id]="dropdownId" type="text" #mapKeyInput #trigger="matAutocompleteTrigger"
        [attr.disabled]="disabled" [formControl]="_formControl" [matAutocomplete]="auto"
        (keydown.enter)="addOption($event)" (focusout)="focusOut($event)" [attr.data-mapkey-name]="testAttrValue">
    <span matSuffix *ngIf="showArrow && !disabled" (click)="trigger.openPanel();$event.preventDefault()" style="cursor: pointer;"><mat-icon
            svgIcon="menu-down"></mat-icon></span>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="getOptionText.bind(this)"
        panelWidth="30%">
        <mat-option *ngFor="let option of filteredList | async" [value]="option?.id" [id]="option?.entityHierarchy"
            [matTooltip]="option.entityHierarchy" matTooltipPosition="left">
            {{option?.entityHierarchy}}
        </mat-option>
        <div class="flex flex-row add-new-sticky" (click)="$event.preventDefault();addNewMapKey()">+ ADD NEW</div>
    </mat-autocomplete>
    <mat-error *ngIf="_formControl.hasError('not-created')">
        Mapkey not created yet
    </mat-error>
    <mat-error *ngIf="_formControl.hasError('required')">
        Mapkey required
    </mat-error>
    <mat-error *ngIf="_formControl.hasError('use-dropdown')">
        Mapkey was not created. Use 'Add New' in the dropdown.
    </mat-error>
</mat-form-field>