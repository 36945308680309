import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppService} from '../../app.service';
import {IConfigService} from '../../config/iconfigservice';
import {AccountApiMapping, MapKeyValidation} from '../../models';
import {BaseService} from '../base.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ReportDownloadService } from '@Services/reportdownload.service';

@Injectable()
export class ApiMappingsService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient, public reportDownloadService: ReportDownloadService) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    static asFormGroup(apiMapping: AccountApiMapping): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(apiMapping.id),
            category: new UntypedFormControl(apiMapping.category),
            elementName: new UntypedFormControl(apiMapping.elementName, Validators.required),
            mapKeyId: new UntypedFormControl(apiMapping.mapKeyId, Validators.required),
            mapKeyValidations: new UntypedFormArray(apiMapping.mapKeyValidations.map(ApiMappingsService.validationsAsFormGroup)),
            readOnly: new UntypedFormControl(apiMapping.readOnly)
        });
    }

    static validationsAsFormGroup(mapKeyValidation: MapKeyValidation): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(mapKeyValidation.id),
            expression: new UntypedFormControl(mapKeyValidation.expression),
            errorMessage: new UntypedFormControl(mapKeyValidation.errorMessage)
        });
    }

    getApiMappings(accountId: any, category: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/apimapping/${category}`;

        return super.getData<any>(url, clientId);
    }


    getApiMappingsExport(accountId: any, category: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/apimapping/${category}/export`;
        const httpPost = this.httpClient.get(url, { headers: this.appService.getHeaders(), responseType: 'blob', observe: 'response' });

        httpPost.subscribe({
            next: (response: HttpResponse<Blob>) => {
                this.reportDownloadService.saveToFileSystem(response, 'csv');
            },
            error: (error) => this.appService.showResponseErrorMsg(error)
        });
    }

    importApiMappings(accountId: string, category: string, fileData: any){
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/apimapping/${category}/import`;

        return this.httpClient.post(url, fileData, {
            headers: this.appService.getUploadHttpHeaders()
        });
    }

    getAllAsFormArray(accountId: any, category: any): Observable<UntypedFormArray> {
        return this.getApiMappings(accountId, category).pipe(map((mappings: AccountApiMapping[]) => {
            const fgs = mappings.map(ApiMappingsService.asFormGroup);
            return new UntypedFormArray(fgs);
        }));
    }

    saveApiMapping(accountId: any, apiMappings: AccountApiMapping[]) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/apimapping`;

        return super.postData(url, apiMappings, clientId);
    }

    deleteApiMapping(accountId: any, id: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/apimapping/${id}`;

        return super.deleteData(url, clientId);
    }

    getNonQuestionMapkeys(accountId: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/nonquestionmapkeys`;

        return super.getData<any>(url, clientId);
    }
}
