import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService, WhiteLabelService } from '../../../services';
import { PurlService } from '../../../services/purl.service';
import { CaseCreatedResponse, PurlData } from '../../../models/case/purl-response.interface';
import { AuthLegacyService } from '../../../auth/auth-legacy.service';
import { PurlQuestion } from '../../../models';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'app/app.service';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import { FeatureToggle } from 'app/enums';

@Component({
    selector: 'app-purl-verification',
    templateUrl: './purl-verification.component.html',
    styleUrls: ['./purl-verification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PurlVerificationComponent implements OnInit, AfterViewInit {
  @ViewChild('waitDialog', { static: true }) waitDialog: TemplateRef<any>;
  @ViewChild('verificationDialog', { static: true }) verificationDialog: TemplateRef<any>;
  @ViewChild('failedDialog', { static: true }) failedDialog: TemplateRef<any>;
  @ViewChild('linkExpiredDialog', { static: true }) linkExpiredDialog: TemplateRef<any>;
  @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;

  purlVerificationForm: UntypedFormGroup;
  isLoading: boolean = true;
  showForm: boolean = false;
  showError: boolean = false;
  waitDialogRef;
  verifyDialogRef;
  purlQuestions: PurlQuestion[];
  purlData: PurlData;
  clientCode;
  attempts: number = 0;
  inError: boolean = false;
  caseId: string;
  userErrors: string[];

  constructor(
    private dialog: MatDialog,
    public purlService: PurlService,
    public authService: AuthLegacyService,
    public routingService: RoutingService,
    public whiteLabelService: WhiteLabelService,
    public activatedRoute: ActivatedRoute,
    public appService: AppService,
    public featureService: FeatureManagerService,
    public router: Router,
    private _fb: UntypedFormBuilder) { }

  ngOnInit(): void {
      this.waitDialogRef = this.dialog.open(this.waitDialog, {
          width: '650px',
          data: {},
          disableClose: true
      });
      this.caseId = this.activatedRoute.snapshot.params['id'];

  }

  ngAfterViewInit() {
      this.setupUser();
  }

  get verificationQuestions() {
      return this.purlVerificationForm.controls["verificationQuestions"] as UntypedFormArray;
  }

  setupUser() {
      const routeQueryParams = this.activatedRoute.snapshot.queryParams;
      const queryParams = Object.keys(routeQueryParams).map((inputName) => ({ key: inputName, value: routeQueryParams[inputName] }));
      const params = {};

      queryParams.forEach(param => {
          params[param.key] = param.value;
      });

      const themeParam = queryParams.find(qp => qp.key.toLowerCase() === 'debug.theme');
      const currentTheme = this.whiteLabelService.resolveTheme(themeParam?.value);
      if (currentTheme?.code) {
          this.clientCode = currentTheme.code;
      } else {
          const clientCodeParam = queryParams.find(qp => qp.key.toLowerCase() === 'client');
          if (clientCodeParam) {
              this.clientCode = clientCodeParam.value;
          }
      }

      this.purlService.initializePurlUser(this.clientCode, params, null, this.caseId).then(initData => {
          this.purlData = initData;
          if (this.purlData.isExpired){
              this.isExpired();
              return;
          }
          this.setupForm();
      }).catch(response => {

          if (response.error.userErrors) {
              this.userErrors = response.error.userErrors;
          } else {
              this.userErrors = ['There was an unexpected error.'];
          }
          this.waitDialogRef.close();
          this.dialog.open(this.errorDialog, {
              width: '650px',
              // panelClass: 'no-border-dialog',
              data: {},
              disableClose: true
          });
      });
  }

  isExpired(){
      this.waitDialogRef.close();
      this.dialog.open(this.linkExpiredDialog, {
          width: '650px',
          // panelClass: 'no-border-dialog',
          data: {},
          disableClose: true
      });
  }

  setupForm() {
      if (!this.purlData.accountPurlSettings.welcomeHeaderText) {
          this.purlData.accountPurlSettings.welcomeHeaderText='Please verify your identity';
      }

      if (!this.purlData.accountPurlSettings.welcomeBodyText) {
          this.purlData.accountPurlSettings.welcomeBodyText = 'To protect your information, we need to ask you a few questions before you can access your application.';
      }

      this.purlQuestions = this.purlData.accountPurlSettings.verificationQuestions;
      this.waitDialogRef.close();

      this.verifyDialogRef = this.dialog.open(this.verificationDialog, {
          width: '450px',
          // panelClass: 'no-border-dialog',
          data: {},
          disableClose: true
      });

      this.resetForm();
  }

  resetForm() {
      this.purlVerificationForm = this._fb.group({
          verificationQuestions: this._fb.array([], Validators.required)
      });

      const verificationQuestionArray = new UntypedFormArray([]);
      this.purlQuestions.forEach(question => {
          verificationQuestionArray.push(this.createQuestion(question));
      });

      this.purlVerificationForm.setControl('verificationQuestions', verificationQuestionArray);
      this.purlVerificationForm.updateValueAndValidity();
  }

  createQuestion(question) {
      return this._fb.group({
          id: [question.id],
          questionText: [question.verificationText],
          verificationAnswer: ['', Validators.required]
      });
  }

  verify() {
      if (!this.purlVerificationForm.valid){
          return;
      }

      const isAuthV2 = this.featureService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;
      let token = this.purlData.tokenDetails.id_token;
      if (isAuthV2){
          token = this.purlData.tokenDetails.access_token;
      }

      this.appService.display(true);
      this.purlService.verifyPurl(token, this.purlData.account.clientId, this.caseId, this.purlVerificationForm.value.verificationQuestions).then(result => {
          this.appService.display(false);
          if (result.isVerificationSuccessful) {
              const existingCase: CaseCreatedResponse = {
                  clientCode: this.clientCode,
                  account: this.purlData.account,
                  id: this.caseId
              };

              this.purlService.navigateToCaseAsync(existingCase, this.activatedRoute);
              this.isLoading = false;
              this.verifyDialogRef.close();
          } else {
              this.showForm = true;
              this.inError = true;
              this.attempts += 1;
              if (this.attempts >= this.purlData.accountPurlSettings.numberOfRetries) {
                  this.verifyDialogRef.close();
                  this.dialog.open(this.failedDialog, {
                      width: '650px',
                      // panelClass: 'no-border-dialog',
                      data: {},
                      disableClose: true
                  });
              }else{
                  this.resetForm();
              }
          }
      });
  }

}
