import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EntityType } from 'app/account/account-config/account-mapkeys/models/mapkey-operation';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { MapKeyType } from '../../enums';
import { Account } from '../../models';
import { AccountDataService, ConfirmationDialogService, MapKeyService, UtilityService } from '../../services';
import { MapKeysModalComponent } from './map-keys-modal.component';

@Component({
    selector: 'map-keys-dropdown',
    templateUrl: './map-keys-dropdown.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: MapKeysDropdownComponent,
        multi: true
    }]
})
export class MapKeysDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() showRequiredError;
  @Input() placeholder;
  @Input() multiple;
  @Input() allowedTypes: MapKeyType[];
  @Input() disabled;
  @Input() showArrow;
  filteredList: Observable<any[]>;
  value: any;
  modalValue: any;
  dataKey = 'id';
  field = 'entityHierarchy';
  account: Account;
  subscriptions: Subscription[] = [];
  @Output() focusin: EventEmitter<any>;
  @Output() valueChanges: EventEmitter<any>;
  @Output() onBlurred: EventEmitter<any>;
  @Output() newMapkeyAdded = new EventEmitter<any>();
  dialogRef: MatDialogRef<MapKeysModalComponent>;
  @ViewChild('mapKeyInput', { read: MatAutocompleteTrigger }) mapKeyInput: MatAutocompleteTrigger;
  private onChange: Function;
  private onTouched: Function;

  dropdownId: string;

  constructor(
    public dialog: MatDialog,
    private confirmationService: ConfirmationDialogService,
    private appService: AppService,
    private mapKeyService: MapKeyService,
    private accountDataService: AccountDataService,
    private utilityService: UtilityService
  ) {
      this.onChange = (_: any) => {
      };
      this.onTouched = () => {
      };

      this.focusin = new EventEmitter<any>();
      this.valueChanges = new EventEmitter<any>();
      this.onBlurred = new EventEmitter<any>();

      this.dropdownId = `dropdownId-${Math.random()}`;
  }

  _formControl: UntypedFormControl;
  testAttrValue: string;

  @Input()
  set formControl(formControl: UntypedFormControl) {
      this._formControl = formControl;
      if (this.utilityService.isGuid(this._formControl.value)) {
          this.testAttrValue = this._displayList.find(x => x.id === this._formControl.value)?.entityHierarchy;
      }
      const sub = this._formControl.valueChanges.subscribe(val => {
          let filterValue = val ? val.toLowerCase() : '';

          if (this.utilityService.isGuid(filterValue)) {
              filterValue = '';
          }

          if (this._displayList) {
              this.filteredList = of(this._displayList.filter(item => {
                  return (item[this.field].toLowerCase().includes(filterValue));
              }));
          }
      });

      this.subscriptions.push(sub);
  }

  _displayList: any = [];

  @Input()
  set displayList(list) {
      if (list && list.length) {
          list = _.sortBy(list, 'entityHierarchy');
      }
      this._displayList = list;
      // this.filteredList = of(list);
  }

  ngOnDestroy(): void {
      if (this.subscriptions) {
          this.subscriptions.forEach(sub => {
              sub.unsubscribe();
          });
      }
  }

  ngOnInit() {

      this.account = this.accountDataService.activeAccount;
  }

  writeValue(value: any): void {
      if (this.multiple) {
          if (!value || !value.length) {
              this.value = [];
          } else {
              this.value = value;
          }

          this.modalValue = [];
          value.forEach(element => {

              if (!_.find(this._displayList, [this.dataKey, element])) {
                  const newItem = {};
                  newItem[this.dataKey] = element;
                  newItem[this.field] = element;
                  this._displayList = _.concat(this._displayList, newItem);
              }

              this.modalValue.push(_.find(this._displayList, [this.dataKey, element]));

          });
      } else {
          this.value = value;
          this.modalValue = _.find(this._displayList, [this.dataKey, value]);
      }
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  getOptionText(option) {
      const m = this._displayList.find((mapkey) => mapkey.id === option);
      if (m) {
          return m.entityHierarchy;
      }
  }

  addNewMapKey() {
      const data = {
          account: this.account,
          mapkeyOperation: {
              isView: false,
              entityType: EntityType.Mapkey,
              isNew: true,
              selectedNode: {},
              isModal: true,
              allowedTypes: this.allowedTypes,
              isSystemGenerated: false,
              entityHierarchy: '',
              name: ''
          }
      };

      this.dialogRef = this.dialog.open(MapKeysModalComponent, {
          width: '50%',
          data: data
      });

      this.dialogRef.afterClosed().subscribe(result => {
          if (result && result.id) {
              this.refreshListAndSelectNewMapkey(result);
          }
      });
  }

  addOption($event) {
      $event.preventDefault();
      if (this.allowedTypes && this.allowedTypes.length === 1 && this.allowedTypes[0] === MapKeyType.Integration) {
          this.focusOut($event);
      } else {
          const mapkeyName = this._formControl.value;
          const mapKeys = this.mapKeyService.mapKeys;

          const m = this._displayList.find((mapkey) => mapkey.entityHierarchy === mapkeyName);
          if (m) {
              this._formControl.setValue(m.id);
              this.mapKeyInput.closePanel();
              return;
          }

          if (this.utilityService.isGuid(mapkeyName)) {
              const existingMapkey = this._displayList.find(mk => mk.id === mapkeyName);
              if (existingMapkey) {
                  this._formControl.setValue(existingMapkey.id);
                  this.mapKeyInput.closePanel();
                  return;
              }
          }

          if (mapkeyName && typeof (mapkeyName) === 'string') {
              if ((mapKeys.find(x => x.entityHierarchy === mapkeyName || x.id === mapkeyName))) {
                  this.appService.showMsg('error', 'Mapkey Already Exists', false);
              } else {
                  this.confirmationService.confirm({
                      message: `Are you sure you want to add "${mapkeyName}" Mapkey?`,
                      key: 'addMapkeyConfirm',
                      accept: () => {
                          this.createMapkey(mapkeyName);
                      },
                      showCancel: true
                  });
              }
          } else if (typeof (mapkeyName) === 'object') {
              this.appService.showMsg('error', 'Mapkey Already Exists', false);
          }
      }
  }

  createMapkey(mapkeyName) {
      const newMapKey = {
          entityHierarchy: mapkeyName,
          clientId: this.account.clientId,
          accountId: this.account.id
      };

      this.mapKeyService.createMapKeyFromDropdown(newMapKey, this.account.id).subscribe(response => {
          if (response.status === 'success') {
              this.appService.showMsg('success', 'Mapkey saved successfully ...');
              this.refreshListAndSelectNewMapkey(response.data, true);
          } else {
              this.appService.showMsg('error', response.message);
          }
      });
  }

  refreshListAndSelectNewMapkey(newMapkey, reloadTreeData?: boolean) {
      this._displayList = [...this._displayList, newMapkey];
      this._formControl.setValue(newMapkey.id);
      this.mapKeyService.getMapKeys(this.account.id, reloadTreeData).subscribe(() => {
      });
      this.mapKeyInput.closePanel();
      this._formControl.setErrors(null);
      this._formControl.updateValueAndValidity();
      if (!reloadTreeData) {
          this.mapKeyService.addChildToNode(newMapkey);
      }
  }

  focusOut($event) {
      const mapkeyName = this._formControl.value;

      const m = this._displayList.find((mapkey) => mapkey.entityHierarchy === mapkeyName || mapkey.id === mapkeyName);

      if (!m && mapkeyName !== '' && mapkeyName !== null) {
          let error: ValidationErrors = { 'not-created': 'This mapkey has not been created yet' };
          if (this.allowedTypes && this.allowedTypes.length === 1 && this.allowedTypes[0] === MapKeyType.Integration) {
              error = { 'use-dropdown': ' Mapkey was not created.Use \'Add New\' in the dropdown.' };
          }
          this._formControl.setErrors(error);
      } else {
          this._formControl.setErrors(null);
          this._formControl.updateValueAndValidity();
      }
  }
}
